import styled from "styled-components"
import { Link } from "gatsby"

export const Inner = styled.div`
  position: relative;
  text-align: ${props => props.textAlign || "left"};
  width: 100%;
`
export const TitleOuter = styled.div`
  display: flex;
  flex-direction: ${props => props.direction || "row"};
  align-items: ${props => props.alignItems || "stretch"};
  justify-content: ${props => props.justifyContent || "flex-start"};
`
export const Title = styled.h1`
  font-size: 4.4vw;
  font-weight: 900;
  line-height: 1;
  color: ${props => props.textColor || "#ffffff"};
  text-decoration: ${props => props.textDecoration || "none"};
`
export const Paragraph = styled.p`
  font-size: 16px;
  line-height: 1.5;
  margin-top: 10px;
  color: ${props => props.textColor || "#ffffff"};
`

export const Button = styled(Link)`
  font-size: 16px;
  line-height: 1.5;
  margin-top: 4%;
  padding: 12px 35px;
  background-color: #ff3355;
  color: #ffffff;
  text-transform: uppercase;
  border-radius: 4px;
  display: inline-block;
`
