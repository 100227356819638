import styled from "styled-components"

import { Link } from "gatsby"

export const Container = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 1000;
`

export const Inner = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: space-between;
`

export const Logo = styled.div``

export const Navigation = styled.div`
  margin: 0 1rem;
`

export const Sidebar = styled.div``

export const NavBar = styled.div`
  display: flex;
  justify-content: center;
`

export const NavLink = styled(Link)`
  font-weight: 400;
  color: #ffffff;
  font-size: 14px;
  display: block;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin: 0 15px;
`
