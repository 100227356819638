import React from "react"

import Layout from "../layout"

import Carousel from "@brainhubeu/react-carousel"
import "@brainhubeu/react-carousel/lib/style.css"

import { Section, Content, Flex, Cell } from "../components"

import { graphql } from "gatsby"
import Img from "gatsby-image"

import Partners from "../container/partners"

export const query = graphql`
  query {
    project: file(relativePath: { eq: "projects/project.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 350, height: 450) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    partners: file(relativePath: { eq: "projects/project.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 120, height: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export default function Home({ data }) {
  return (
    <Layout>
      <Section>
        <Section.Inner>
          <Content textAlign="center">
            <Content.TitleOuter direction="column" justifyContent="center">
              <Content.Title>WeDesign</Content.Title>
              <Content.Title textColor="#FF3355" textDecoration="underline">
                UniqueWeb
              </Content.Title>
              <Content.Title>Experience</Content.Title>
            </Content.TitleOuter>
            <Content.Button>Let’s Talk!</Content.Button>
          </Content>
        </Section.Inner>
      </Section>
      <Section>
        <Section.Inner>
          <Content textAlign="center">
            <Content.TitleOuter justifyContent="center">
              <Content.Title textColor="#FF3355">Global&nbsp;</Content.Title>
              <Content.Title>is Good</Content.Title>
            </Content.TitleOuter>
            <Content.Paragraph>
              We’re able to improve user expeirence for their consumers all
              around the world
            </Content.Paragraph>
            <Partners logos={data.partners.childImageSharp.fixed} />
          </Content>
        </Section.Inner>
      </Section>
      <Section>
        <Section.Inner>
          <Flex alignItems="center" justifyContent="space-between">
            <Flex.Column size="4">
              <Content>
                <Content.TitleOuter>
                  <Content.Title>Solution</Content.Title>
                </Content.TitleOuter>
                <Content.Paragraph>
                  We work with businesses and organizations of all sizes to
                  express their personality and values via a comprehensive and
                  consistent user experience.
                </Content.Paragraph>
              </Content>
            </Flex.Column>
            <Flex.Column size="7">
              <Flex alignItems="center">
                <Flex.Column size="4">
                  <Cell>
                    <Cell.Title>ID</Cell.Title>
                    <Cell.SubTitle>ideation</Cell.SubTitle>
                  </Cell>
                </Flex.Column>
                <Flex.Column size="4">
                  <Cell>
                    <Cell.Title>UI</Cell.Title>
                    <Cell.SubTitle>user interface</Cell.SubTitle>
                  </Cell>
                  <Cell>
                    <Cell.Title>CO</Cell.Title>
                    <Cell.SubTitle>Coding</Cell.SubTitle>
                  </Cell>
                </Flex.Column>
                <Flex.Column size="4">
                  <Cell>
                    <Cell.Title>UX</Cell.Title>
                    <Cell.SubTitle>user Experience</Cell.SubTitle>
                  </Cell>
                </Flex.Column>
              </Flex>
            </Flex.Column>
          </Flex>
        </Section.Inner>
      </Section>
      {/* <Section>
        <Section.Inner> */}
      <Section.InnerNormal>
        <Carousel centered arrows infinite slidesPerPage={3}>
          <Img fixed={data.project.childImageSharp.fixed} />
          <Img fixed={data.project.childImageSharp.fixed} />
          <Img fixed={data.project.childImageSharp.fixed} />
        </Carousel>
      </Section.InnerNormal>

      {/* </Section.Inner>
      </Section> */}
      <Section>
        <Section.Inner>
          <Flex alignItems="center" justifyContent="space-between">
            <Flex.Column size="4">
              <Content>
                <Content.TitleOuter>
                  <Content.Title>Liquid</Content.Title>
                  <Content.Title textColor="#FF3355">Apps</Content.Title>
                </Content.TitleOuter>
                <Content.Paragraph>
                  is a family of young storytellers based in sunny Colombo, Sri
                  Lanka. We are trying to see things differently to solve the
                  challenges via team collaboration, collective experience,
                  design, and technical expertise. We embrace new challenges
                  with an open heart, a hunger to learn, and a passion for
                  solving problems as a team.
                </Content.Paragraph>
              </Content>
            </Flex.Column>
            <Flex.Column size="4">
              <svg
                width="280"
                height="407"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M279.905 260.328v-.669a155.698 155.698 0 00-2.547-22.584c-.04-.221-.087-.449-.127-.67-.159-.81-.319-1.62-.492-2.43C257.657 138.992 152.153 64.445 140.048.228l-.04-.228c0 .074 0 .154-.047.228C127.863 64.444 22.359 138.992 3.304 233.975c-.173.81-.333 1.62-.493 2.43-.04.228-.086.455-.126.67a154.742 154.742 0 00-2.547 22.584v.669c-.06 1.815-.107 3.636-.107 5.464C.031 346.285 59.777 407 140.035 407c80.257 0 140.003-60.715 140.003-141.208a298.975 298.975 0 00-.133-5.464zm-139.897 97.085a87.689 87.689 0 01-41.175-10.278 88.36 88.36 0 01-31.64-28.448 89.114 89.114 0 01-14.777-40.031 89.298 89.298 0 015.506-42.345 123.56 123.56 0 0122.965-33.424 86.737 86.737 0 016.086-5.068c-6.106 10.451-8.846 23.26-3.498 36.102 5.347 12.842 18.762 25.255 30.548 20.863 6.411-2.39 11.832-8.429 14.978-15.533 3.485-7.888 3.086-17.047 1.177-25.858-2.042-9.408-5.501-18.313-7.922-27.593a102.4 102.4 0 01-3.052-21.552c-.579-14.061 1.197-29.99 6.338-43.126 4.363-10.378 10.482-18.346 17.432-22.618a29.873 29.873 0 015.986-2.678 30.765 30.765 0 00-2.494 13.036c.001 2.027.224 4.048.665 6.026 7.01 37.194 68.113 80.346 79.147 135.377l.279 1.406c0 .134.054.261.073.395a88.893 88.893 0 011.477 13.076v.382a83.34 83.34 0 01.066 3.167 89.269 89.269 0 01-6.709 33.966 88.773 88.773 0 01-19.112 28.795 88.11 88.11 0 01-28.604 19.24 87.647 87.647 0 01-33.74 6.754v-.033z"
                  fill="#F35"
                />
              </svg>
            </Flex.Column>
            <Flex.Column size="4"></Flex.Column>
          </Flex>
        </Section.Inner>
      </Section>
    </Layout>
  )
}
