import React from "react"

import { Row, Column } from "./styles/flex"

export default function Flex({ children, ...restProps }) {
  return <Row {...restProps}>{children}</Row>
}

Flex.Column = function ({ children, ...restProps }) {
  return <Column {...restProps}>{children}</Column>
}
