import styled from "styled-components"

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 30px;
`

export const GridColumn = styled.div`
  grid-column: span ${props => props.column || "1"};
  grid-row: span ${props => props.row || "1"};
`
