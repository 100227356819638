import styled from "styled-components"

export const Row = styled.div`
  display: flex;
  width: 100%;
  align-items: ${props => props.alignItems || "stretch"};
  justify-content: ${props => props.justifyContent || "flex-start"};
  flex-wrap: ${props => (props.flexWrap ? "wrap" : "nowrap")};
`

export const Column = styled.div`
  flex: 0 0 ${props => props.size * (100 / 12)}%;
  max-width: ${props => props.size * (100 / 12)}%;
`
