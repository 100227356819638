import React from "react"

import { Inner, TitleOuter, Title, Paragraph, Button } from "./styles/content"

export default function Content({ children, ...restProps }) {
  return <Inner {...restProps}>{children}</Inner>
}

Content.TitleOuter = function ({ children, ...restProps }) {
  return <TitleOuter {...restProps}>{children}</TitleOuter>
}

Content.Title = function ({ children, ...restProps }) {
  return <Title {...restProps}>{children}</Title>
}

Content.Paragraph = function ({ children, ...restProps }) {
  return <Paragraph {...restProps}>{children}</Paragraph>
}

Content.Button = function ({ children, ...restProps }) {
  return <Button {...restProps}>{children}</Button>
}
