import React from "react"

import { Container, Inner, InnerNormal } from "./styles/section"

export default function Section({ children, ...restProps }) {
  return <Container {...restProps}>{children}</Container>
}

Section.Inner = function ({ children, ...restProps }) {
  return <Inner {...restProps}>{children}</Inner>
}

Section.InnerNormal = function ({ children, ...restProps }) {
  return <InnerNormal {...restProps}>{children}</InnerNormal>
}
