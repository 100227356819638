import React from "react"

import {
  Container,
  Inner,
  Navigation,
  Logo,
  Sidebar,
  NavBar,
  NavLink,
} from "./styles/header"

export default function Header({ children, ...restProps }) {
  return <Container {...restProps}>{children}</Container>
}

Header.Inner = function ({ children, ...restProps }) {
  return <Inner {...restProps}>{children}</Inner>
}

Header.Logo = function ({ children, ...restProps }) {
  return <Logo {...restProps}>{children}</Logo>
}

Header.Navigation = function ({ children, ...restProps }) {
  return <Navigation {...restProps}>{children}</Navigation>
}

Header.Sidebar = function ({ children, ...restProps }) {
  return <Sidebar {...restProps}>{children}</Sidebar>
}

Header.NavBar = function ({ children, ...restProps }) {
  return <NavBar {...restProps}>{children}</NavBar>
}

Header.NavLink = function ({ children, ...restProps }) {
  return <NavLink {...restProps}>{children}</NavLink>
}
