import styled from "styled-components"

export const Container = styled.div`
  /* padding: 3.25rem; */
  padding: 5%;
  display: flex;
  align-items: center;
  min-height: 100vh;
`

export const Inner = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 5%;
`

export const InnerNormal = styled.div`
  display: flex;
  align-items: center;
  min-height: 100vh;
`
