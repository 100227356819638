import React from "react"

import styled from "styled-components"

import Img from "gatsby-image"

export const PartnerWrap = styled.div`
  padding: 3.25rem 0;
  margin: 0 -11%;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  overflow: hidden;
  align-items: center;
`

export const PartnerItem = styled.div`
  padding: 0 1rem;
`

export default function Partners({ logos }) {
  return (
    <PartnerWrap>
      {/* {logos.map(logo => ( */}
      <PartnerItem>
        <Img fixed={logos} />
      </PartnerItem>
      <PartnerItem>
        <Img fixed={logos} />
      </PartnerItem>
      <PartnerItem>
        <Img fixed={logos} />
      </PartnerItem>
      <PartnerItem>
        <Img fixed={logos} />
      </PartnerItem>
      <PartnerItem>
        <Img fixed={logos} />
      </PartnerItem>
      <PartnerItem>
        <Img fixed={logos} />
      </PartnerItem>
      <PartnerItem>
        <Img fixed={logos} />
      </PartnerItem>
      <PartnerItem>
        <Img fixed={logos} />
      </PartnerItem>
      <PartnerItem>
        <Img fixed={logos} />
      </PartnerItem>
      <PartnerItem>
        <Img fixed={logos} />
      </PartnerItem>
      <PartnerItem>
        <Img fixed={logos} />
      </PartnerItem>
      <PartnerItem>
        <Img fixed={logos} />
      </PartnerItem>
      <PartnerItem>
        <Img fixed={logos} />
      </PartnerItem>
      {/* ))} */}
    </PartnerWrap>
  )
}
