import React from "react"
import { Header } from "../components"

// import { SmoothProvider } from "react-smooth-scrolling"
import { Helmet } from "react-helmet"
import styled, { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`
  * {
      padding: 0;
      margin: 0;
      box-sizing: border-box;
      text-decoration: none;
      line-height: 1.5;
      font-family: 'Mulish', sans-serif;
  }

  html, body {
    background-color: #181818;
  } 

  .scrollArea {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow-x:hidden;
    overflow-y: auto;
  }

  .container {
    margin: 0 auto;
    width: 100%;
    max-width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

`

const Wrapper = styled.div`
  width: 100% !important;
  /* height: 100vh !important; */
`

export default function Layout({ children }) {
  return (
    <>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800;900&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <GlobalStyle />
      <Header>
        <Header.Inner>
          <Header.Logo>lo</Header.Logo>
          <Header.Navigation>
            <Header.NavBar>
              <Header.NavLink to="/">Clients</Header.NavLink>
              <Header.NavLink to="/">Services</Header.NavLink>
              <Header.NavLink to="/">Projects</Header.NavLink>
              <Header.NavLink to="/">About</Header.NavLink>
              <Header.NavLink to="/">Connect</Header.NavLink>
            </Header.NavBar>
          </Header.Navigation>
          <Header.Sidebar>si</Header.Sidebar>
        </Header.Inner>
      </Header>
      {/* <SmoothProvider> */}
      <Wrapper>{children}</Wrapper>
      {/* </SmoothProvider> */}
    </>
  )
}
