import styled from "styled-components"

export const Inner = styled.div`
  position: relative;
  padding-bottom: 110%;
  background-color: rgba(46, 46, 46, 0.06);
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 4px;
  margin: 20px 10px;
`

export const Title = styled.h1`
  font-family: Mulish;
  font-style: normal;
  font-weight: 900;
  font-size: 7.5vw;
  color: #ff3355;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  text-align: center;
`
export const SubTitle = styled.p`
  font-family: Mulish;
  font-style: normal;
  font-size: 0.85vw;
  color: #ffffff;
  position: absolute;
  left: 0;
  bottom: 15%;
  right: 0;
  text-align: center;
  text-transform: uppercase;
`
