import React from "react"

import { Inner, Title, SubTitle } from "./styles/cell"

export default function Cell({ children, ...restProps }) {
  return <Inner {...restProps}>{children}</Inner>
}

Cell.Title = function ({ children, ...restProps }) {
  return <Title {...restProps}>{children}</Title>
}

Cell.SubTitle = function ({ children, ...restProps }) {
  return <SubTitle {...restProps}>{children}</SubTitle>
}
